<template>
  <div>
    <v-form data-vv-scope="form-event-role" data-testid="form-event-role">
      <v-card class="mb-2">
        <v-card-title>
          <span class="headline" v-html="getTitle()"></span>
        </v-card-title>
        <v-card-text>
          <v-row class="pt-3">
            <v-col
              cols="12"
              md="12"
              v-if="errors.has('form-bundle-registered.general_error_field')"
            >
              <v-row>
                <v-col cols="12" md="6">
                  <v-alert type="error" text>
                    {{ errors.first("form-event-role.general_error_field") }}
                  </v-alert>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="activeEventMemberRole.name"
                :error-messages="errors.collect('form-event-role.title')"
                label="Name"
                placeholder=""
                data-vv-name="name"
                data-vv-validate-on="change"
                data-vv-as="Name"
                outlined
                v-validate="'required'"
                dense
                data-testid="title"
              />
            </v-col>

            <v-col cols="12" sm="12" class="justify-center">
              <v-checkbox
                v-model="activeEventMemberRole.is_capture_studio"
                label="Is capture studio"
                hide-details
                class="mt-0 mb-4"
                data-testid="chbx-is-capsule-studio"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="error" text :to="'/store/event/roles'">Close</v-btn>
          <v-btn color="success" text @click.stop="save()">Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import GeneralMixin from "@/mixins/general.js"

export default {
  props: {
    editedIndex: {
      type: [Number, String],
      default: -1,
    },
  },

  mixins: [GeneralMixin],

  created: function () {
    let self = this
    self.$store.dispatch("saveLoaderAction", true)
    self.$store.dispatch("resetEventMembersRoleItem").then(() => {
      self.getItemData().then(() => {
        self.$store.dispatch("saveLoaderAction", false)
      })
    })
  },

  beforeDestroy() {
    this.$store.dispatch("resetEventMembersRoleItem")
  },

  methods: {
    getTitle() {
      return this.editedIndex === -1
        ? "Add new event member role"
        : `<span class="blue-grey--text text--lighten-4">Edit event member role</span> ${this.activeEventMemberRole.name}`
    },
    getItemData() {
      return this.editedIndex !== -1
        ? this.$store.dispatch("getEventMemberRoleItem", {
            slug: this.editedIndex,
          })
        : Promise.resolve()
    },
    createEventMemberRole(formData) {
      let self = this
      self.$store
        .dispatch("createEventMemberRole", formData)
        .then(({ data }) => {
          let params = {
            text: `Event member role <strong>${this.activeEventMemberRole.name}</strong> was added successfully.`,
            show: true,
          }
          self.close().then(() => {
            self.$root.$emit("snackbar", params)
          })
        })
        .catch(({ response }) => {
          self.handleValidationErrors(response, "form-event-role")
        })
    },
    editEventMemberRole(formData) {
      let self = this
      self.$store
        .dispatch("editEventMemberRole", formData)
        .then(({ data }) => {
          let params = {
            text: `Event member role <strong>${this.activeEventMemberRole.name}</strong> was edited successfully.`,
            show: true,
          }
          self.close().then(() => {
            self.$root.$emit("snackbar", params)
          })
        })
        .catch(({ response }) => {
          self.handleValidationErrors(response, "form-event-role")
        })
    },
    save() {
      let self = this
      self.$validator.errors.clear("form-event-role")
      self.$validator.validateAll("form-event-role").then((result) => {
        if (result) {
          let formData = {
            data: {
              name: self.activeEventMemberRole.name,
              is_capture_studio: self.activeEventMemberRole.is_capture_studio,
            },
          }
          if (this.editedIndex !== -1) {
            formData["slug"] = self.editedIndex
            self.editEventMemberRole(formData)
          } else {
            self.createEventMemberRole(formData)
          }
        }
      })
    },
    close() {
      let self = this
      return new Promise((resolve, reject) => {
        self.$router
          .push({ path: "/store/event/roles" })
          .then(() => {
            resolve()
          })
          .catch((err) => {})
      })
    },
  },

  computed: {
    ...mapGetters({
      showTableLoader: "getShowTableLoader",
      activeEventMemberRole: "activeEventMemberRole",
      Pallette: "get_Pallette",
    }),
  },
}
</script>
